html{
  padding: env(safe-area-inset); 
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  display: flex;
  height: auto;
  overflow-x: hidden;
  position: relative;
  text-rendering: optimizeLegibility;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

h1,
h2,
h3,
h4 {
  line-height: 1;
  margin: 0;
}
::placeholder {
  color: #cccccc;
}

.container,
.app,
#root {
  width: 100%;
}
img {
  width: 100%;
  height: 100%;
}

html[dir='rtl'] .lang-swither-container {
  margin-left: 15px;
  margin-right: 0;
}

.vjs-text-track-cue,
.vjs-text-track-cue *{
  font: unset !important; 
}

/* Mute/unmute volume control should show on iOS */
/* .video-js .vjs-mute-control { display: inline-block !important } */
 
.custom-unmute-button{
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  gap: 10px; 
  cursor: pointer;
  background-color: #000;
  color: #fff;
  font-size: 14px;
  height: auto !important;
  left: 50%;
  margin: 12px 0;
  opacity: .8;
  padding: 12px;
  position: absolute;
  top: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: visibility .5s linear, opacity .5s linear, font-size .1s ease;
  opacity: 0;
  z-index: -1;
}
 
.custom-unmute-button svg {
  width: 24px;
  height: 24px; 
} 

.custom-unmute-button.show-unmute {
  opacity: 1;
  z-index: 203;
}

.vjs-subs-caps-button{
  display: inline-block !important;
}

 
.vjs-big-play-button {
  border: none !important;
  box-shadow: none !important;
  background-color: #4D4D4F !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  transform: translateX(50%);
}

.vjs-icon-placeholder:before{
  top: 2px !important;
}

.rotate-phone{
  max-height: 100vh;
  width: 100%;
  object-fit: contain;
  background: #000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 11;
  overflow: hidden;
}

.hide{
  display: none;
}

 